<!--
 * @Author: liuyufeng tom.liu@ulugame.com
 * @Date: 2022-07-04 09:54:15
 * @LastEditors: liuyufeng tom.liu@ulugame.com
 * @LastEditTime: 2022-07-21 09:57:34
 * @FilePath: \sgdts-gat\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view />
       <v-dialog id="dialog" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      screenWidth:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
    }
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        (window.screenWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth),
          (that.screenWidth = window.screenWidth);
        this.fun();
      })();
    };
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    fun() {
      const that = this;
      var htmlobj = document.getElementsByTagName("html")[0];
      htmlobj.style.fontSize = (that.screenWidth / 1920) * 20 + "px";
    }
 }
}
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.el-loading-mask{
    background-color: rgba(0,0,0,0.4)!important;
}
.el-textarea__inner,.el-input__inner {
    background-color: transparent !important;
}

</style>
