/*
 * @Author: liuyufeng tom.liu@ulugame.com
 * @Date: 2022-03-25 10:24:00
 * @LastEditors: liuyufeng tom.liu@ulugame.com
 * @LastEditTime: 2022-06-21 11:03:10
 * @FilePath: \windowssdk\src\lang\tw.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  customer: {
    feedback: "意見回饋",
    scene: "問題場景",
    email: "信箱",
    address: "玩家地址",
    message: "留言",
    uploadPicture: "圖片上傳",
    submit: "提交",
    received: "已接收",
    checked: "已查看",
    answered: "已回覆",
    nomore: "没有更多~",
    imgsize: "要求圖片大小小於5M",
    usernameTip: "請輸入用戶名",
    emailTip: "請輸入合理的郵箱地址",
    mesageTip: "請輸入留言",
    "Feedback-list": "我的回饋",
    "feedback-submit": "提交回饋",
    reply: "回覆",
    underProcessing: "提交中...",
    ok: "確認",
    cancel: "取消",
    loginError: "登錄問題",
    accountError: "賬號問題",
    actError: "活動異常",
    payError: "充值問題",
    bugError: "遊戲BUG",
    sugError: "建議提交",
    reportError: "我要舉報",
    otherError: "其他問題",
    gameError: "遊戲攻略",
    outError: "閃退問題",

    payAmount: "支付金額",
    errorTime: "錯誤發生時間",
    cus: "客服",
    score: "評分",
    extOrderNo: "訂單號",
    extOrderNoTip: "請輸入訂單號",
  },
  userCenter: {
    AccountStatus: "帳號狀態",
    VistorUnsafe: "遊客/不安全",
    BindingSafe: "已綁定/安全",
    Email: "郵箱",
    Forgetpassword: "忘記密碼",
    Bind: "前往綁定",
    Unbind: "解除绑定",
    Switch: "切換帳號",
    tip: "使用帳號綁定功能可以保護你的遊戲進度",
    Retrievepassword: "找回密碼",
    BindAccount: "帳號綁定",
    Code: "驗證碼",
    Sendcode: "發送驗證碼",
    password8: "不少於8位密碼",
    Bindsuccessfully: "綁定成功",
    Unbindsuccessfully: "解綁成功",
    validEmail: "請輸入有效的郵箱",
    Inputcode: "請輸入驗證碼",
    Inputpassword: "請輸入密碼",
    Modifysuccessfully: "修改成功",
    Databaseerror: "資料庫異常",
    operror: "資料庫操作異常",
    Parameterinfounsafe: "參數資訊不全",
    Nopermission: "沒有許可權",
    Emailformatwrong: "郵箱格式不正確",
    Invaildlogin: "無效登入.請重新嘗試",
    Emailwasbinded: "該郵箱帳號已綁定過",
    noexist: "帳號不存在",
    Codeerror: "驗證碼錯誤",
    bindedalready: "該第三方帳號已綁定過",
    ULUaccount: "該帳號已經綁定了ULU帳號",
    Codeinvalid: "驗證碼已失效",
    Requestfrequently: "請求太頻繁，請稍後再試",
    ok: "確認",
    cancel: "取消",
    oneBind: "請在解綁所有第三方賬號前，先綁定一個郵箱",
    idError: "賬號錯誤，請輸入正確的解綁賬號",
    errorMessage1: "提交失敗",
    errorMessage2: "重複參加",
    privatePolitics: "用戶條款",
    privateInfo: "隱私政策",
    notRegister: "未註冊",
    confirmPrivate: "用確認用戶協議",
    email: "郵箱",
    emailLogin: "郵箱登錄",
    password: "密碼",
    rewirte: "修改",
    rewritepassword: "修改密碼",
    user: "用戶",
    logout: "退出登錄",
    clickLogin: "點擊登錄",
    codeSend: "驗證碼已發送",
    notLogin: "未登錄",
    passwordError: "密碼錯誤",
    selectItem: "選擇商品",
    itemName: "商品名",
    serverName: "伺服器",
    roleId: "角色id",
    roleName: "角色暱稱",
    passCar: "通行證",
    payWay: "支付方式",
    bankCard: "信用卡/借記卡",
    eWallet: "電子錢包",
    choosePay: "請選擇實名認證方式",
    creditCard: "信用卡",
    phone: "手機",
    confirmSuccess: "認證完成，登入成功",
    cancelInfo: "已取消實名認證",
    InfoFail: "實名認證失敗",
    paySuccess: "支付完成",
    payFail: "支付失敗",
    children: "未成年",
  },
  survey: {
    survey: "調研問卷",
    complete: "調研已結束",
    tip: "這裡有一份調研問卷",
    tip1: "填寫完畢即可獲得獎勵",
    reward: "问卷奖励",
    write: "填寫問卷",
    next: "下一頁",
    submit: "提交問卷",
    success: "感謝參與！問卷獎勵已發放",
    success1: "請前往遊戲內“信箱”領取",
    disabled: "已填寫",
    other: "其他",
  },
};