
<template>
    <div class="success">
    
   <div v-show="showModalend" class="box8s">
       
    </div>
    <div v-show="showModalend" class="showModalend">
       <p v-html="this.userShowpay"></p>
       <p v-html="this.datte"></p>
        <p @click="btnend">確認</p>
        
    </div>
  
    </div>
</template>
<script>



//1失败，2取消
export default {
    data() {
        return {
            showModalend:true,
            userShowpay:'',
            access_tok:'',
            datte:''
            
        }
    },
    methods: {
        btnend(){
          this.showModalend=false
          window.location.href='https://pay.ulugame.com/#/'
        },
    parseParam(url =location.href) {
      // 只要 ? 后面的数据
      const [, params] = url.split("?");
      const result = {};
      params?.split("&").map((item) => {
        let [key, value = ""] = item.split("=");
        value = typeof value === Boolean ? value : decodeURIComponent(value); // 转译中文
        if (!result[key]) {
          result[key] = value;
        } else {
          // 重复出现的 key 要组装成数组
          result[key] = [].concat(result[key], value);
        }
      })
      return result
    }
    },
    mounted() { 
        this.access_tok=this.parseParam(window.location.href)
        if(this.access_tok.code){
            if(this.access_tok.code!="0"){
                let ddat=this.access_tok.msg
                var arr=ddat.split('%2B')
                let resultful=arr.join('')
                console.log(resultful);
                this.datte=resultful
            }
      }
    },
    created(){
      this.userShowpay=this.$t('userCenter.payFail')
    //    let ddat="System%2Berror.%2BPlease%2Btry%2Bagain%2Bor%2Bcontact%2Bcustomer%2Bservice"
    //   var arr=ddat.split('%2B')
    //   console.log(arr);
    //   let resultful=arr.join('')
    //   console.log(resultful);
    //    console.log(decodeURIComponent(ddat));
    // console.log(ddat);
    },
    computed: {
     
    },
    watch: {
           
    }
}
</script>
<style lang="scss" scoped>
.success{
    width: 100vw;
    height: 100vh;
    background-image: url("../assets/pay/success.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.box8s{
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/GAT/greyBG.png");
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}
.showModalend{
    &>:nth-child(2){
      color: #e3bf67;
      font-size: 1vw;
      text-align: center;
      margin-top: -1.5vw;
      word-wrap: break-word;
      word-break: normal;
      margin-left: 5vw;
      width: 25vw;
    }
}
</style>