const sizes=`個人資料暨隱私權保護政策
易亨數位行銷有限公司（以下簡稱本公司）為保障您個人資料之安全性，特針對您個人的隱私權擬定本《個人資料暨隱私權保護政策》（Privacy Policy，下簡稱隱私權政策），讓您瞭解本公司對於您的隱私權之尊重與保護，並告訴您有關您個人資料之相關資訊。
為了確保您之個人資料及隱私及您權益之保護，本公司依照《個人資料保護法》等相關規定於蒐集前告知。本政策將長期於本公司網站進行公告，本政策進行修訂時，亦將以公告方式通知您，請您自行密切注意。

本政策讓您瞭解本公司在蒐集您的個人資料時：
1. 本公司名稱及適用範圍。
2. 蒐集之目的及方式。
3. 蒐集之個人資料類別。
4. 個人資料利用之期間、地區、對象及方式。
5. 您得行使之權利及方式。
6. 若您無法提供完整的個人資料時，不提供將對您權益之影響。
7. 個人資料的安全維護。
8. 內容之變更通知。
 
一、適用範圍
本隱私權政策適用於您在本公司網站、應用程式(APP)與其所附屬之網站活動及參與其他網路連線活動時，所涉及的個人資料蒐集、處理、利用、國際傳遞以及保護措施。但不適用於經本公司網站而連結至第三人經營的網站或網頁等。
 
二、蒐集之目的及方式
本公司之蒐集目的在於進行行銷、客戶管理、內部統計分析、會員管理及其他合於營業登記項目或章程所定業務之需要 (法定特定目的項目編號為040行銷、052會員管理、090消費者、客戶管理與服務、157調查、統計與研究分析、181其他經營合於營業登記項目或組織章程所定之業務)。 
蒐集方式將透過加入會員、各項服務表單填寫或訂購單填寫等方式進行，您可自行選擇是否提供資料及所提供資料的完整性，但某些網站、應用程式(APP)功能、服務或活動必須根據您所提供的資料方得使用；提請知悉，您若不提供完整而正確的個人資料，部分網站、應用程式(APP)功能、服務或活動可能無法使用。
 
三、蒐集之個人資料類別
1.本公司在適用範圍內蒐集的個人資料包括 
（1） C001 辨識個人者：如姓名、地址、電話、相片、電子郵件等。
（2） C002 辨識財務者：如銀行帳戶之號碼等。
（3） C011 個人描述：如年齡、性別、出生年月日等資訊。
（4） C035 休閒活動及興趣:如嗜好、運動等資訊。
（5） C036 生活格調:如使用消費品之種類及服務之細節等資訊。
（6） C038 職業:如司機、校長、老師等職業資訊。
（7） C064 工作經驗:如以前的工作、服役情形等工作資訊。
（8） C132 未分類之資料:如檔案、報告等資訊。
（9） 本公司將使用cookies進行網站管理及記錄您的活動紀錄、網站使用動態等。所謂cookies，為由本公司存放於您電腦中的瀏覽器目錄內，僅為本公司設置cookies的網站能讀取其內容。您可以透過您的瀏覽器設定以變更您cookies設置；您可以關掉Cookies，但可能會導致網站某些功能無法正常執行。
（10）本公司網站及應用程式(APP)管理系統將紀錄您上網的IP位置、瀏覽器種類、使用時間及網站內所瀏覽的網頁記錄等軌跡資料，並依照《個人資料保護法》對相關軌跡資料進行保存。
2. 根據本公司所提供不同服務目的，本公司可能蒐集您的個人資料類別及情形如下：
(1) 加入會員
當您申請加入會員時，您將會被要求填寫一份您的個人資料，包括姓名、性別、生日、電話、電子信箱等相關資料，並設定您的帳號密碼，以便您日後登入使用會員的各項服務。
(2)線上交易
如您選擇使用線上交易服務，將會依照您提出的線上交易形態，蒐集您所填寫的訂單相關資料，以確保您完成付款、儲值或物流等消費服務。
(3) 線上活動、現場活動、賽事活動
您若因參加本公司舉辦之線上活動或網路調查而提供個人資料、會員帳號或密碼時，亦將一併受到隱私權政策之保護。
(4) 瀏覽網頁
當您於本公司網站、應用程式(APP)或其附屬網站中瀏覽或查詢時，本公司伺服器將自動紀錄您使用連線之IP位置、時間及瀏覽相關記錄。這些資料僅供作流量統計分析及網路服務優化，以便於改善本公司的服務品質，這些資料僅作為總量上的分析，不會和特定個人相連繫。
(5) 其他
除您直接提供的個人資料外，您也可能在本公司合作廠商處主動提供個人資料，並在您同意或為履行契約目的前提下，將您的個人資料提供給本公司。這些資料只會在您同意或為履行契約的服務範圍內利用及處理，不會用於其他用途。 
3.特殊或具敏感性的個人資料
我們不會要求您也請您不要提供特殊或具敏感性的個人資料給我們，例如您的病歷、醫療、政治、宗教、哲學信仰、種族、性取向、犯罪紀錄或基因等。
4.您可以自由選擇是否提供您的個人資料給我們，但請您知悉，如未提供足夠資料者（除本條第3款載明的無需提供的特殊或具敏感性的個人資料），部分服務將無法為您提供，敬請見諒。建議您提供完整的個人資料並隨時更新，若因您未提供完整且確實的個人資料，造成您或第三人發生損害時，您應自行負擔一切責任。
 
四、個人資料利用之期間、地區、對象及方式
    1. 利用期間
本公司將於營運期間保存您的個人資料，除法令另有規定者外，於您使用本公司網站、應用程式(APP) 或其所附屬網站服務完畢或至您提出停止使用之請求為止。如您欲刪除或移除個人資料，請循本公司官網的聯絡方式與我們聯繫。參加活動者之個人資料使用自活動參與者授權參與活動起始日至活動結束後三(3)個月。依據稅法規定，活動得獎者之個人資料最長保存七(7)年，屆時銷毀，不作他用。
    2. 利用地區
中華民國（台灣）地區及提供本公司網站、應用程式(APP)與其所附屬網站服務之地區。
您之個人資料將用於全球。您應知悉，當您將個人資料提供予我們時，您的個人資料皆將可能在我們位處各國的關係企業、服務供應商、經授權的第三方廠商和承包商間進行跨境傳輸、儲存及處理利用；上述跨境傳輸、儲存及處理利用行為皆將遵循本協定及各國個人隱私及資訊安全之相關法令要求。
    3. 利用對象
除法令另有規定者外，本公司將僅供您同意之服務目的及本公司內部在相關連之範圍內使用，不會任意將您提供的個人資料轉供其他第三人或移作其他目的使用。但基於履行契約目的，本公司將提供相關資料予協辦單位或合作廠商，以完成履行契約義務。
    4. 利用方式
基於上述蒐集目的，您的個人資料除用於本公司之會員行銷、客戶管理、內部的統計調查與分析、會員管理等方式以外，本公司將依您的實際需要，将您個人資料利用如下：
（1）資料管理
當您申請加入會員時，您將會被要求填寫一份您的個人資料，包括姓名、性別、生日、電話、住址、電子信箱等相關資料，並設定您的帳號密碼，以便您日後登入使用您的各項服務。您在加入會員時所填寫的資料，本公司將會進行手機及e-mail驗證，並用於分析您個人的線上消費行為，以作為將來提供您所需之詳盡資料以及作為設計您活動時之依據。 
（2）線上交易
如您選擇使用線上交易服務，本公司將會依照您提出的線上交易形態，蒐集您所填寫的訂單相關資料、金融交易過程(如信用卡授權、轉帳)時直接提交給金融機構以完成金融交易，以確保您完成付款、儲值或物流等消費服務。
（3）統計與分析
當您於本公司網站、應用程式(APP)或其附屬網站中瀏覽或查詢時，本公司伺服器將自動紀錄您使用連線之IP位置、時間及瀏覽相關記錄。這些資料僅供作統計分析及網路服務優化，以便於改善本公司的服務品質，本公司可能針對您提供的資料，以及站內瀏覽時伺服器所產生的紀錄，不定期對這些資料進行總體分析與統計，但不會和特定個人相連繫。
（4） 遊戲行銷
本公司將利用您之地址、郵件、電話號碼進行本公司或合作廠商商品之行銷宣傳，並為此完善向您提供的遊戲服務。
（5）會員服務
本公司將利用您所提供之各項資料提供予您各項服務及福利，具體包括：
a. 訂閱電子報
當您使用本公司網站、應用程式(APP)與其所附屬網站服務，您可能會收到相關電子報。若您欲取消訂閱，請洽本公司客服專線或本公司線上回報系統與我們聯繫。
b. 線上活動、現場活動、賽事活動
您若因參加本公司舉辦之線上活動或網路調查所留下的個人資料，僅供該次活動或調查使用。這些資料將於活動或調查結束後銷毀。這些資料將於活動或調查結束後一定時間內銷毀。
c. 虛擬、寶物及贈品寄送
於交寄相關商品時，將您的個人資料利用於交付給相關物流、郵寄廠商用於物品寄送之目的。
d. 其他會員服務
本公司將利用您所提供之各項資料提供其他各項會員服務，包括本公司所提供的服務及產品之市場研究報告、定期通訊、活動邀請、市場訊息等。
（6）政府行政或司法之所需
基於政府單位之通知，本公司將提供您於本遊戲中之所有個人資料。
      
五、個人資料行使之權利及方式
1.您交付個人資料予本公司，依《個人資料保護法》得行使以下權利：
(1) 查詢或請求閱覽。
(2) 請求製給電子版複製本。
(3) 請求補充或更正。
(4) 請求停止蒐集、處理或利用。 
(5) 請求刪除。 
以上權利，若因您不符合本公司申請程序或法令另有規定時，將會受到限制。提請知悉，若您就您的個人資料向本公司查詢、請求閱覽個人資料或製給電子版複製本時，本公司將酌收必要成本費用。
2.您如欲行使上述權利，您可循本公司官網的聯絡方式與我們聯繫並進行申請 。請您知悉，為保護您的隱私，申請時您需填具申請文件，我們可以向您請求提供可資確認之身份證明文件，因您未提供或提供不實數據情形時，以致於無法確認您的身份真實性的，我們將無法處理您的請求。若委託他人代為申請者，應出具委任書，並提供本人及代理人可資確認之身份證明文件。
 
六、您應提供正確的個人資料
所有關於您的個人資料之欄位請務必確實填寫，若您無法提供完整且確實的個人資料，將影響本公司和您的聯繫、完成交易、提供服務、身份驗證或處理消費、交易或客訴問題，建議您應提供完整的個人資料及隨時更新。
若您未提供完整且確實個人資料，對您及第三人造成損害時，您應負擔一切責任。如未來因您提供的不實資料發生爭議(例如偽冒身分)，本公司將視狀況進行法律訴追。如造成本公司損失的，本公司有權向您請求損害賠償。
 
七、安全維護
關於您的個人資料檔案，本公司將採行適當之安全措施，並盡力以合理之技術及措施，依相關法令辦理安全維護事項，防止您的個人資料被竊取、竄改、毀損、滅失或洩漏。    
我們會保護您的個人資料不會被披露，但如遇有下列情形，本公司將保留公開您個人資料的權利予特定人：
1.您或其監護人授權本公司披露、使用或提供的；
2.違反本公司網站或產品之使用者條款等的；或因您的行為對本公司之網站、權利、產品或其他用戶造成損害或干擾的；或受損害干擾人因而對您採取法律行動的；
3.法律訴訟及/或強制執行判決及/或命令；及/或
4.為遵守有關法律規範或主管機關命令的。
 
八、自我保護措施
1. 請妥善保管您的密碼及/或您的任何個人資料，不要將您的個人資料提供給任何人，建議您的密碼避免設定為與其他的遊戲、網站、應用程式(APP)、信箱、社群、論壇的密碼相同。
2. 在您完成繳費、取閱電子郵件、結束遊戲等程序後，務必記得登出帳戶，若您與他人共享電腦或使用公共電腦，切記要關閉瀏覽器視窗或消除瀏覽記錄等，以防止他人讀取您的個人資料或信件。
3. 避免開啟來路不明的網站及其附件檔案；不要安裝或執行來路不明之程式，或前往外掛網站、論壇等，以防止您資料的外洩與避免電腦遭木馬程式或其他病毒植入之機會。
4. 官方人員、活動人員及線上遊戲管理人員等，皆不會主動向任何玩家要求密碼資料，不論是任何理由皆是不被允許的，若發現有此類情況，請您立即回報給本公司進行處理。
 
九、隱私權政策變更
本隱私權政策的解釋、修改及更新權均屬於本公司所有。
本公司有權自行修訂本隱私權政策，相關修正將會公告於本網站或應用程式(APP)內，或以電子郵件方式通知您，請您密切注意以保障個人權益。
如果您不同意本隱私權政策及其變更之內容，請您立即停止使用本公司網站、應用程式(APP)與其所附屬之網站服務。本隱私權政策將長期於本公司網站進行公告，須請您自行密切注意；當您於隱私權政策變更後繼續使用本公司服務，表示您已同意本隱私權政策及其變更之內容。
 
十、安全聲明 
1.我們將通過合理步驟確保個人資料安全。儘管我們會採取安全防護和預防措施，但還是存在著您的個人資料可能會被未授權的第三方廠商看到的風險，或受到電腦病毒的攻擊及其它安全隱患。
2.若您通過本網站連結至其它網站，則請知悉第三方廠商網站不受本公司管轄，本公司亦不對其行為負責。請注意：您自身提供給第三方廠商的個人資料不受本隱私權政策制約，我們強烈建議您仔細瞭解第三方廠商隱私和安保方面的協議或條款。
任何情況下，請您注意小心妥善保管個人資料，若因您自身原因導致您個人資料或隱私洩露或給您造成損失或其他不利影響的，本公司對此不承擔任何責任。
十一、效力等級
本公司有權自行修訂本《個人資料保護政策》之內容，包括為配合相關法令之異動。本隱私權政策若存在與您所在地的法律法規不一致的條款，請以當地法律法規為準。
 
十二、看法與建議
您如果對本公司的隱私權政策或與您個人資料有關之事項有任何意見或疑問，歡迎您透過本公司客服信箱【tycs001@gmail.com】與我們聯繫。
`
export default sizes