/*
 * @Author: liuyufeng tom.liu@ulugame.com
 * @Date: 2022-03-25 10:24:00
 * @LastEditors: liuyufeng tom.liu@ulugame.com
 * @LastEditTime: 2022-06-21 10:57:19
 * @FilePath: \windowssdk\src\lang\en.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  customer: {
    feedback: "Feedback",
    scene: "Problem scene",
    email: "Email",
    address: "Address",
    message: "Message",
    uploadPicture: "Upload pictures",
    submit: "Submit",
    received: "Received",
    checked: "Viewed",
    answered: "Replied",
    nomore: "No more~",
    imgsize: "Require imagesize<5M",
    usernameTip: "Please enter user name",
    emailTip: "Please enter a valid email address",
    mesageTip: "Please leave messages",
    "Feedback-list": "My feedback",
    "feedback-submit": "Submit feedback",
    reply: "Reply",
    underProcessing: "under processing...",
    ok: "confirm",
    cancel: "cancel",
    loginError: "Login issue",
    accountError: "Account issue",
    actError: "Event abnormity",
    payError: "Top-up issue",
    bugError: "Game Bug",
    sugError: "Suggestion",
    reportError: "Report",
    otherError: "Other issue",
    gameError: "Game strategy",
    outError: "Game crash issue",
    payAmount: "Payment amount",
    errorTime: "Error occurrence time",
    cus: "Customer service",
    score: "grade",
    extOrderNo: "order number",
    extOrderNoTip: "Please enter your order number",
  },
  userCenter: {
    AccountStatus: "Account Status",
    VistorUnsafe: "Vistor/Unsafe",
    BindingSafe: "Binding/Safe",
    Email: "Email",
    Forgetpassword: "Forget password",
    Bind: "Bind",
    Unbind: "Unbind",
    Switch: "Switch",
    tip: "Bind account will save game progress.",
    Retrievepassword: "Retrieve password",
    BindAccount: "Bind Account",
    Code: "Code",
    Sendcode: "Send code",
    password8: "Password requires at least 8 characters.",
    Bindsuccessfully: "Bind successfully",
    Unbindsuccessfully: "Unbind successfully",
    validEmail: "Please enter a valid email",
    Inputcode: "Input code",
    Inputpassword: "Input password",
    Modifysuccessfully: "Modify successfully",
    Databaseerror: "Data base error",
    operror: "Operation errored in data base",
    Parameterinfounsafe: "Incomplete parameter information",
    Nopermission: "No permission",
    Emailformatwrong: "Email format wrong",
    Invaildlogin: "Invalid login. Please try again",
    Emailwasbinded: "Email was binded",
    noexist: "Account doesn't exist",
    Codeerror: "Code error",
    bindedalready: "This account was binded already.",
    ULUaccount: "Already binded with ULU account",
    Codeinvalid: "Code invalid",
    Requestfrequently: "Request too frequently. Please try again later.",
    ok: "confirm",
    cancel: "cancel",
    oneBind: "Please bind a e-mail before unbinding all third-party channels",
    idError:
      "Incorrect account， please type the right account which you want to unbind",
    errorMessage1: "Submission Failed",
    errorMessage2: "Repeat participation",
    privatePolitics: "User Agreement",
    privateInfo: "Privacy Policy",
    notRegister: "Unregistered",
    confirmPrivate: "Confirm User Agreement",
    emailLogin: "login with email",
    email: "email",
    password: "password",
    passwordError: "wrong password",
    rewrite: "alter",
    rewritepassword: "alter password",
    user: "User",
    logout: "Log out",
    clickLogin: "Tap on log in",
    codeSend: "verification code",
    notLogin: "Not Login",
    selectItem: "select items",
    itemName: "item name",
    serverName: "server",
    roleId: "character id",
    roleName: "nickname",
    passCar: "Pass",
    payWay: "payment method",
    bankCard: "credit card/debit card",
    eWallet: "e-wallet",
    choosePay: "select verification method",
    creditCard: "credit card",
    phone: "mobile phone",
    confirmSuccess: "verified successed",
    cancelInfo: "cancelled verification",
    InfoFail: "verfication failed ",
    paySuccess: "payment completed",
    payFail: "payment failed",
    children: "Underage",
  },
  survey: {
    survey: "Questionnaire",
    complete: "The survey is over",
    tip: "Here is a survey questionnaire",
    tip1: "Fill in to get reward",
    reward: "Reward for survey",
    write: " Take Survey",
    next: " Next",
    submit: "Submit",
    success: " Questionnaire reward has been issued",
    success1: 'Please receive it in the "Mailbox" in the game',
    disabled: "Filled",
    other: "Other",
  },
};