<!--
 * @Author: your name
 * @Date: 2022-03-23 14:22:01
 * @LastEditTime: 2022-07-21 09:59:35
 * @LastEditors: liuyufeng tom.liu@ulugame.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \windowssdk\src\components\chooseLogin.vue
-->
/* eslint-disable */
<template>
<div v-show="showLogin" class="login_bg">
    <div v-if="showUser" class="content">
        <div>
            <div class="content-title">用戶ID:{{uid}}</div>
            <div class="logout" @click="logout">退出登錄</div>

        </div>
        <img style="position:absolute;right:0;top:-50px" @click="closeModal" :src="require('../assets/pay/close.png')">
    </div>
    <div v-else class="content">
        <div class="title">ULU平臺賬號登錄</div>
        <div class="form">
            <div><span class="label">{{ $t('userCenter.email') }}</span> <el-input class="form_child" v-model="mail"/></div>
            <!-- <div class="form_child"> -->
            <div><span class="label">{{ $t('userCenter.password') }}</span> <el-input show-password class="form_child" v-model="password"/></div>
            <img class="login_bt" src="../assets/login/login_bt.png" @click="loginUlu">
          
            <img style="position:absolute;right:0;top:-50px" @click="closeModal" :src="require('../assets/pay/close.png')">
            <!-- <div><span>密码</span></div> -->
            <!-- </div> -->
        </div>
        <!-- <img
        class="close"
        :src="require('../assets/login/close_gray.png')"
        @click="closeDialog"
      /> -->
        <div class="other">
            其他登錄
        </div>
        <div class="choose_list">
            <img :src="require('../assets/login/facebook.png')" @click="changeLogin('facebook')">
            <img :src="require('../assets/login/google.png')" @click="changeLogin('google')">
            <img :src="require('../assets/login/ios.png')" @click="changeLogin('apple')">
            <!-- <div class="font-style" @click="changeLogin('google')">
          Google계정 로그인
        </div> -->
            <!-- <div class="font-style" @click="changeLogin('apple')">Apple계정 로그인</div> -->
            <!-- <div
          class="font-style"
          style="color: white"
          @click="changeLogin('ulu')"
        >
          U.LU계정 로그인
        </div> -->
            <!-- <div class="font-style" @click="changeLogin('facebook')">
          Facebook계정 로그인
        </div> -->
        </div>

        <!-- <div class="policy">
        <div> -->
        <!-- <img
            :src="
              policyList[0]
                ? require('../assets/login/check.png')
                : require('../assets/login/uncheck.png')
            "
            @click="checkPolicy(0)"
          /> -->
        <!-- <a :href="linkUrlList[0]">[{{ $t("userCenter.privatePolitics")}}]</a>
        </div>
        <div> -->
        <!-- <img
            :src="
              policyList[1]
                ? require('../assets/login/check.png')
                : require('../assets/login/uncheck.png')
            "
            @click="checkPolicy(1)"
          /> -->
        <!-- <a :href="linkUrlList[1]">[{{$t("userCenter.privateInfo")}}]</a> -->
        <!-- </div>
      </div> -->
    </div>
</div>
</template>

<script>
/* eslint-disable */

import {
    binding,
    register,
    logout
} from "../util/request";
import {
    mapMutations,
    mapGetters
} from "vuex"
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider,getAuth, signInWithPopup } from "firebase/auth";
let app = null
export default {
    data() {
        return {
            policyList: [true, true],
            showChoose: false,
            loginTypes: {
                ulu: 2,
                facebook: 3,
                google: 4,
                twitter: 5,
                gamecenter: 6,
            },
            mail: "",
            password: "",
            token:''
        };
    },
    computed: {
        ...mapGetters(['lanuage', 'showLogin', 'isLoading', 'uid']),
        showUser(){
                return !!this.token
        },
        isEmail() {
            return /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/.test(
                this.mail
            )
        },
        linkUrlList() {
            let val = this.lanuage
            return (val == 'ko' ? ['http://policy.ulugame.com/policykr.html', 'http://policy.ulugame.com/kr.html'] : [
                `http://policy.ulugame.com/policy${val=='tw'?'cn':val}.html`, `http://policy.ulugame.com/${val=='tw'?'cn':val}.html`
            ])
        }
    },
    watch: {

    },
    mounted() {
        //this.showLogin = !localStorage.getItem('token')
        this.token=localStorage.getItem('token')
        const firebaseConfig = {
  apiKey: "AIzaSyB3o-EFO-AHtIYXBtvSiG7ZprCQdSlUsEQ",
  authDomain: "sgdtshmt.firebaseapp.com",
  projectId: "sgdtshmt",
  storageBucket: "sgdtshmt.appspot.com",
  messagingSenderId: "682467742573",
  appId: "1:682467742573:web:656219c008c9d653e94064",
  measurementId: "G-WPT82332RV"
};

// Initialize Firebase
        app = initializeApp(firebaseConfig);
        this.token=localStorage.getItem('token')
    },
    methods: {
        ...mapMutations([
            'setHeadImg',
            'setOtherEmail',
            'setFacebookName',
            'setUid',
            'setToken',
            'setIsLoading',
            'setShowLogin',
            'setShowPay'
        ]),
        closeModal() {
            this.setShowLogin(false)
        },
              loginGoogle(name) {
            const provider = new GoogleAuthProvider();
        // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
          const auth = getAuth(app);
//auth().currentUser.getIdToken
signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    console.log(888,credential)
      const user = result.user;
     this.bindAccount( credential.idToken,user.providerData[0].uid,name)
    console.log(123000,credential,user)
    // ...
  }).catch((error) => {
    // // Handle Errors here.
    // const errorCode = error.code;
    // const errorMessage = error.message;
    // // The email of the user's account used.
    // const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log(error)
    // ...
  });
    },
        logout() {
            logout()
            // switch (localStorage.getItem("loginWay")) {
            //     case "facebook":
            //         FB.logout(function () {
            //             // user is now logged out
            //         });
            //         break;
            //     case "google":
            //         var auth2 = gapi.auth2.getAuthInstance();
            //         auth2.signOut().then(function () {
            //             //alert('用户注销成功');
            //         });
            //         break;

            //     default:
            //         break;
            // }
            localStorage.clear()
            history.go(0)
            window.location.href='https://pay.ulugame.com/#/'
        },
        loginUlu(accessToken = "", accessId = "") {
            if (!this.isEmail) {
                this.$toasted.show(this.$t('userCenter.Emailformatwrong'))
                return
            }
            if (!this.password) {
                this.$toasted.show('密码为空')
                return
            }
            localStorage.clear()
            // window.open(`https://testpgapi.payletter.com/v1.0/auth/card/?client_id=ULUGAMES`)
            //let md5 = crypto.createHash("md5");
            //md5.update(this.password);
            let password = this.password //md5.digest("hex").toUpperCase();
            const data = {
                loginType: 2,
                accessId,
                accessToken: '',
                gameId: 10060,
                mail: this.mail,
                password,
                uniqueId:null
            };
            this.setIsLoading(true)
            binding(data).then((res) => {
                //this.$toast.clear()
                this.setIsLoading(false)
                if (res.code == 0 && res.data) {
                    this.$emit('onClose', false)
                    if (res.data.certificateFlag == 0) {
                        this.$emit('loginSuccess')
                    } else {
                        if (res.data.token) {
                            localStorage.setItem('token', res.data.token)
                            this.setToken(res.data.token)
                            this.setShowPay(true)
                            console.log(this.showUser);
                        
                            this.token=localStorage.getItem('token')
                        } else {
                            localStorage.clear()
                        }
                    }
                    // this.init();
                    localStorage.setItem('email', this.mail)
                    localStorage.setItem('uid', res.data.uid)
                    localStorage.setItem('password', this.password)
                    localStorage.setItem('loginType', '0')
                    this.setUid(res.data.uid)
                    this.setShowLogin(false)
                    this.$toasted.success(this.$t("userCenter.Bindsuccessfully"), {
                        position: 'top-center',
                        duration: 3000
                    });
                } else {
                    getCode(res.code);
                    localStorage.clear()
                }
            });
        },
        getMethod(name) {
            console.log(6777, name)
        },
        async changeLogin(name) {
            localStorage.clear()
            //console.log(name);
            //       const policyList = [...this.policyList]
            //         let arr = policyList.filter(item => !item)
            //         if (arr.length > 1) { 
            //             this.$toasted.show(this.$t('userCenter.confirmPrivate'))
            //                return
            //         }
            //         if (policyList[0] == false) {
            //             this.$toasted.show(this.$t('userCenter.privatePolitics'))
            //             return
            //         } else if(policyList[1] == false){ 
            //             this.$toasted.show(this.$t('userCenter.privateInfo'))
            //    return
            //         }
            //       this.$emit("loginChange", name);
            if (name == "facebook") {
                window.FB.login((res) => {
                    console.log(888, res)
                    if(res.authResponse.accessToken!=null){
                        this.bindAccount(res.authResponse.accessToken, res.authResponse.userID, name)
                        localStorage.setItem("loginWay",name)
                    }
                });
            }
            if(name == "apple"){
         const a = AppleID.auth.init({
           clientId: "com.ulugame.2xtwweb",
           redirectURI: "https://sdk.ulugame.com/v2/api/member/apple/getCode",
           usePopup: false,
           scope: "email",
           state: "100163",
          });
          console.log(7888, a);
          try {
                    const task = AppleID.auth.signIn();
                    localStorage.setItem("isapple", "1");
                    
                    task.finally((e) => {
                        console.log(123, e);
                    });
                    task.catch((err) => {
                        console.log(89900, err);
                    });
                } catch (e) {
                    console.log(88999, e);
                }
        }
            if (name == "google") {
                this.loginGoogle(name)
                // gapi.load("auth2", () => {
                //     let auth2 = gapi.auth2.init({
                //         // client_id:
                //         //   '372395845963-qidir2a8uasj8ari12m345fq93fquu36.apps.googleusercontent.com',
                //         apiKey: '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',
                //         client_id: "409239384662-jk68k9jmnkl899dchecm672k3f3a8agf.apps.googleusercontent.com",
                //         cookiepolicy: "single_host_origin",
                //     });
                //     auth2.signIn().then((res) => {
                //         console.log(1234, res);
                //         if (res) {
                //             //const {wc} = res
                //             // this.setHeadImg(auth2.currentUser.get().getBasicProfile().getImageUrl())
                //             this.setOtherEmail(auth2.currentUser.get().getBasicProfile().getEmail())
                //             // console.log(7888,,)
                //             //this.setShowLogin(false)
                //             this.bindAccount(auth2.currentUser.get().getAuthResponse().id_token, auth2.currentUser
                //                 .get()
                //                 .getBasicProfile()
                //                 .getId(), name)
                //                 localStorage.setItem("loginWay",name)
                //         }
                //     });
                // });
            }
        },
        bindAccount(accessToken = "", accessId = "", name) {
            const data = {
                loginType: this.loginTypes[name],
                accessId,
                accessToken,
            };
            //this.showChoose=true

            binding(data).then((res) => {
                this.setShowLogin(false)
                // this.showLogin=false
                //this.$toast.clear();
                //debugger
                //console.log(88991,res)
                if (res.data) {
                    //this.init();
                    //   this.$toasted.success(  this.$t("userCenter.Bindsuccessfully"),{

                    //     duration: 3000,
                    //     position:'top-center'
                    //   });
                    localStorage.setItem('token', res.data.token)
                    this.setToken(res.data.token)

                    localStorage.setItem('uid', res.data.uid)
                    this.setUid(res.data.uid)
                    history.go(0)
                    //console.log(889911,res.data)

                } else {
                    //console.log(code)
                    //getCode(res.code);
                }
            });
        },
        bindAccounts(accessToken = "", accessId = "") {    
      const data = {
        loginType: 8,
        accessId,
        accessToken,
      };
       //this.showChoose=true
       
        binding(data).then((res) => {
            this.setShowLogin(false)
         // this.showLogin=false
        //this.$toast.clear();
       
        
      //console.log(88991,res)
        if (res.data) {
          //this.init();
        //   this.$toasted.success(  this.$t("userCenter.Bindsuccessfully"),{

        //     duration: 3000,
        //     position:'top-center'
        //   });

               localStorage.setItem('token',res.data.token)
               this.setToken(res.data.token)
              localStorage.setItem('uid',res.data.uid)
              this.setUid(res.data.uid)
            //   this.token=localStorage.getItem('token')
              history.go(0)
        
       // history.go(0)

        } else {
            //console.log(code)
          //getCode(res.code);
        }
      });
    },
        register() {
            register({
                mail: '',
                password: '',
                gameId: ''
            }).then(res => {
                console.log(888, res)
            })
        },
        checkPolicy(key) {
            this.$set(this.policyList, key, !this.policyList[key]);
        },
        closeDialog() {
            console.log(8777, this.$t("userCenter.Bindsuccessfully"))
            // this.$toasted.show(this.$t("userCenter.Bindsuccessfully"),{position:'top-center',duration:4000,singleton:true})
            this.$emit("loginChange", "");
        },
    },
};
</script>

<style lang="scss" scoped>
.login_bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;

    .content {
        padding-top: 4vw;
        width: 50vw;
        height: 29.5vw;
        position: absolute;
        top: 50%;
        left: 50%;
        background-image: url(../assets/login/login_bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transform: translate(-50%, -50%);
        cursor: pointer;

        &>.title {
            font-size: 2rem;
            text-align: center;
            margin-bottom: 2vw;
            margin-left: 1.1vw;
        }
        &>.close {
            position: absolute;
            right: 14px;
            top: 14px;
            width: 1vw;
            z-index: 1;
        }

        &>.choose_list {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-top: 1vw;

            &>img {
                width: 3vw;
                height: 3vw;
            }

            &>img:nth-child(2) {
                margin-left: 2vw;
            }
            &>img:nth-child(3) {
                margin-left: 2vw;
            }
            .font-style {
                color: gray;
                line-height: 3.5vw;
                font-size: 20px;
            }

        }

        &>.policy {
            display: flex;
            flex-direction: column;
            margin-left: 2vw;
            margin-top: 2.2vw;

            &>div {
                display: flex;
                flex-direction: row;
                font-size: 1.5rem;
                align-items: center;
            }

            &> :nth-child(2) {

                margin-top: 1vw;
            }

            & img {
                width: 1.1vw;
                height: 1.1vw;
                margin-right: 7px;
            }
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    &>div {
        line-height: 2.5rem;
      
    }

    & .label {
        font-size: 1.8rem;
        margin-right: 1.8rem;
        vertical-align: middle;
    }
       & .form_child.el-input::v-deep{
            width:20vw;
            height: 2.5rem;
            font-size: 1.5rem;
            color: #fff;
             background-color: transparent!important;
             & input{
                background-color: transparent!important;
                  height: 2.5rem;
                  color: #fff;
             }
    }
    & input {
        width: 20vw;
        height: 2.5rem;
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
        font-size: 1.5rem;
    }

    &>:nth-child(2) {
        margin-top: 2rem
    }
}

.other {
    text-align: center;
    margin: 0 auto;

    margin-top: 1.4vw;
    height: 2.5vw;
    width: 40vw;
    background-image: url('../assets/login/line.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 2rem;
}

.login_bt {
    width: 14vw;
    margin-top: 1.5vw;
}

.content-title {
    font-size: 30px;
    text-align: center;
}

.logout {
    font-size: 36px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 5vw;
}
 input {
	 background-color:transparent;
     background-image: none;
}
</style>
