/*
 * @Author: liuyufeng tom.liu@ulugame.com
 * @Date: 2022-07-04 09:54:15
 * @LastEditors: liuyufeng tom.liu@ulugame.com
 * @LastEditTime: 2022-07-15 11:04:41
 * @FilePath: \sgdts-gat\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */


import Vue from "vue";
import App from "./App.vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueSocialSharing from "vue-social-sharing";
import Carousel3d from "vue-carousel-3d";
import ElementUI from "element-ui";
import Toasted from "vue-toasted";
import VModal from "vue-js-modal/dist/index.nocss.js";
import router from "./router";
import i18n from "./lang/index.js";
import store from "./store/index.js";
import "element-ui/lib/theme-chalk/index.css";
import "./style.css";
import "normalize.css/normalize.css";
import "swiper/swiper-bundle.css";
Vue.use(VueAwesomeSwiper /* { default global options } */);
Vue.config.productionTip = false;
Vue.use(VueSocialSharing);
Vue.use(ElementUI);

Vue.use(Toasted, {
  position: "top-center",
  duration: 5000,
  singleton: true,
  className: "toasted-big",
});
Vue.use(VModal, { dialog: true });
//Vue.use(ElementUI);

Vue.use(Carousel3d);
new Vue({
  store,
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");
