/*
 * @Author: liuyufeng tom.liu@ulugame.com
 * @Date: 2022-07-04 09:54:15
 * @LastEditors: liuyufeng tom.liu@ulugame.com
 * @LastEditTime: 2022-07-04 10:37:17
 * @FilePath: \sgdts-gat\src\router.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import paySuccess from './views/paySuccess.vue'
import payFail from './views/payFail.vue'
Vue.use(Router)

const routes = [{
  path: '/',
  name: 'Home',
  component: Home
},
{
  path: '/:id(\\d+)',
  name: 'home',
  component: Home
},
{
  path:'/paySuccess',
  name:'paySuccess',
  component:paySuccess
},
{
  path:'/payFail',
  name:'payFail',
  component:payFail
}
]

const router = new Router({
  mode: 'hash',
  routes
})

export default router