/*
 * @Author: liuyufeng tom.liu@ulugame.com
 * @Date: 2022-05-12 16:00:43
 * @LastEditors: liuyufeng tom.liu@ulugame.com
 * @LastEditTime: 2022-07-15 13:37:13
 * @FilePath: \windowssdk\src\store\module\app.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { getPaymentType } from "../../util/request";
const app = {
  state: {
    uid: localStorage.getItem("uid"),
    headImg: localStorage.getItem("headImg"),
    otherEmail: localStorage.getItem("otherEmail"),
    facebookName: localStorage.getItem("facebookName"),
    token:localStorage.getItem("token"),
    payTypeList: [],
    isLoading: false,
    lanuage: localStorage.getItem("lanuage") || "tw",
    showPay: false,
    showLogin:true,
  },
  getters: {
    uid: (state) => state.uid,
    headImg: (state) => state.headImg,
    otherEmail: (state) => state.otherEmail,
    facebookName: (state) => state.facebookName,
    token: (state) => state.token,
    payTypeList: (state) => state.payTypeList,
    isLoading: (state) => state.isLoading,
    lanuage: (state) => state.lanuage,
      lan: () => ({ cn: "zh-CN", tw: "zh-TW", en: "en-US", ko: "ko-KR" }),
      showLogin: state => state.showLogin,
      showPay:state=>state.showPay
    
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUid(state, uid) {
      state.uid = uid;
    },
    setHeadImg(state, headImg) {
      localStorage.setItem("headImg", headImg);
      console.log(8991122, headImg);
      state.headImg = headImg;
    },
    setOtherEmail(state, otherEmail) {
      localStorage.setItem("otherEmail", otherEmail);
      console.log(8991122, otherEmail);
      state.otherEmail = otherEmail;
    },
    setFacebookName(state, name) {
      state.facebookName = name;
    },
    setPayTypeList(state, payTypeList) {
      //console.log(1, payTypeList);
      state.payTypeList = payTypeList;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setLanuage(state, lanuage) {
      state.lanuage = lanuage;
    },
    setShowLogin(state, showLogin) {
      state.showLogin = showLogin;
    },
    setShowPay(state, showPay) {
      state.showPay = showPay;
    },
  },
  actions: {
    async setPayInfo(store, pay) {
      console.log(156622, pay);
      if (!pay) {
        return;
      }
      store.commit("setIsLoading", true);
      const { code, data } = await getPaymentType(pay);
      if (code == 0 && data) {
        //console.log(889900, data);
        store.commit("setPayTypeList", data);
        //setTimeout(()=>{  store.commit("setIsLoading", false);},1000)
      }
    },
  },
};
export default app;
