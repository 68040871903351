/*
 * @Author: liuyufeng tom.liu@ulugame.com
 * @Date: 2022-03-25 10:24:00
 * @LastEditors: liuyufeng tom.liu@ulugame.com
 * @LastEditTime: 2022-06-21 10:56:02
 * @FilePath: \windowssdk\src\lang\cn.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  customer: {
    feedback: "意见反馈",
    scene: "问题场景",
    email: "邮箱",
    address: "玩家地址",
    message: "留言",
    uploadPicture: "图片上传",
    submit: "提交",
    received: "已接收",
    checked: "已查看",
    answered: "已回复",
    nomore: "没有更多~",
    imgsize: "要求图片大小小于5M",
    usernameTip: "请输入用户名",
    emailTip: "请输入合理的邮箱地址",
    mesageTip: "请输入留言",
    "Feedback-list": "我的反馈",
    "feedback-submit": "提交反馈",
    underProcessing: "提交中...",
    reply: "回复",
    ok: "确认",
    cancel: "取消",
    loginError: "登录问题",
    accountError: "账号问题",
    actError: "活动异常",
    payError: "充值问题",
    bugError: "游戏BUG",
    sugError: "建议提交",
    reportError: "我要举报",
    otherError: "其他问题",
    gameError: "游戏攻略",
    outError: "闪退问题",
    payAmount: "支付金额",
    errorTime: "错误发生时间",
    cus: "客服",
    score: "评分",
    extOrderNo: "订单号",
    extOrderNoTip: "请输入订单号",
  },
  userCenter: {
    AccountStatus: "账号状态",
    VistorUnsafe: "游客/不安全",
    BindingSafe: "已绑定/安全",
    Email: "邮箱",
    Forgetpassword: "忘记密码",
    Bind: "前往绑定",
    Unbind: "解除绑定",
    Switch: "切换账号",
    tip: "使用账号绑定功能可以保护你的游戏进度",
    Retrievepassword: "找回密码",
    BindAccount: "账号绑定",
    Code: "验证码",
    Sendcode: "发送验证码",
    password8: "不少于8位密码",
    Bindsuccessfully: "绑定成功",
    Unbindsuccessfully: "解绑成功",
    validEmail: "请输入有效的邮箱",
    Inputcode: "请输入验证码",
    Inputpassword: "请输入密码",
    Modifysuccessfully: "修改成功",
    Databaseerror: "数据库异常",
    operror: "数据库操作异常",
    Parameterinfounsafe: "参数信息不全",
    Nopermission: "没有权限",
    Emailformatwrong: "邮箱格式不正确",
    Invaildlogin: "无效登录.请重新尝试",
    Emailwasbinded: "该邮箱账号已绑定过",
    noexist: "账号不存在",
    Codeerror: "验证码错误",
    bindedalready: "该第三方账号已绑定过",
    ULUaccount: "该账号已经绑定了ULU账号",
    Codeinvalid: "验证码已失效",
    Requestfrequently: "请求太频繁，请稍后再试",
    ok: "确认",
    cancel: "取消",
    oneBind: "解绑所有第三方渠道前，请先绑定一个邮箱",
    idError: "账号错误，请输入正确的解绑账号",
    errorMessage1: "提交失败",
    errorMessage2: "重复参加",
    privatePolitics: "用户条款",
    privateInfo: "隐私政策",
    notRegister: "未注册",
    confirmPrivate: "请确认用户协议",
    emailLogin: "邮箱登录",
    email: "邮箱",
    password: "密码",
    passwordError: "密码错误",
    rewrite: "修改",
    rewritepassword: "修改密码",
    user: "用户",
    logout: "退出登录",
    clickLogin: "点击登录",
    codeSend: "验证码已发送",
      notLogin: "未登录",
selectItem:"选择商品",
itemName:"商品名",
serverName:"服务器",
roleId:"角色id",
roleName:"角色昵称",
passCar:"通行证",
payWay:"支付方式",
bankCard:"信用卡/借记卡",
eWallet:"电子钱包",
choosePay:"请选择实名认证方式",
creditCard:"信用卡",
phone:"手机",
confirmSuccess:"认证完成，登录成功",
cancelInfo:"已取消实名认证",
InfoFail:"实名认证失败",
paySuccess:"支付完成",
payFail:"支付失败",
children:"未成年",
  },
  survey: {
    survey: "调研问卷",
    complete: "调研已结束",
    tip: "这里有一份调研问卷",
    tip1: "填写完毕即可获得奖励",
    reward: "问卷奖励",
    write: "填写问卷",
    next: "下一页",
    submit: "提交问卷",
    success: "感谢参与！问卷奖励已发放",
    success1: "请前往游戏内“信箱”领取",
    disabled: "已填写",
    other: "其他",
  },
};