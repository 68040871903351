/*
 * @Author: liuyufeng tom.liu@ulugame.com
 * @Date: 2022-03-25 10:24:00
 * @LastEditors: liuyufeng tom.liu@ulugame.com
 * @LastEditTime: 2022-07-04 10:56:41
 * @FilePath: \windowssdk\src\lang\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import en from './en'
import ko from './ko'
import ja from './ja'
import cn from './cn'
import tw from './tw'

const messages = {
  'en': {
    ...en
  },
  'ko': {
    ...ko
  },
  'ja': {
    ...ja
  },
  'cn': {
    ...cn
  },
  'tw': {
    ...tw
  }
}
const i18n = new VueI18n({
  locale: 'tw',
  messages
})
export default i18n