/*
 * @Author: your name
 * @Date: 2022-03-24 15:05:47
 * @LastEditTime: 2022-07-15 12:34:02
 * @LastEditors: liuyufeng tom.liu@ulugame.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \windowssdk\src\utils\request.js
 */
import axios from "axios";
//import Cookies from "js-cookie";
import i18n from "@/lang/index";
import Vue from "vue";
export function getCode(code) {
  switch (code) {
    case 10000:
      Vue.toasted.show(i18n.t("userCenter.Databaseerror"));
      break;
    case 20000:
      Vue.toasted.show(i18n.t("userCenter.operror"));
      break;
    case 30100:
      Vue.toasted.show(i18n.t("userCenter.Parameterinfounsafe"));
      break;
    case 30300:
      localStorage.clear();
      window.location.href = "/";
      Vue.toasted.show(i18n.t("userCenter.Invaildlogin"));
      break;
    case 40001:
      Vue.toasted.show(i18n.t("userCenter.Emailformatwrong"));
      break;
    case 40002:
      Vue.toasted.show(i18n.t("userCenter.Emailwasbinded"));
      break;
    case 40003:
      Vue.toasted.show(i18n.t("userCenter.noexist"));
      break;
    case 40004:
      Vue.toasted.show(i18n.t("userCenter.Codeerror"));
      break;
    case 40005:
      Vue.toasted.show(i18n.t("userCenter.bindedalready"));
      break;
    case 40006:
      Vue.toasted.show(i18n.t("userCenter.passwordError"));
      break;
    case 40007:
      Vue.toasted.show(i18n.t("userCenter.ULUaccount"));
      break;
    case 40008:
      Vue.toasted.show(i18n.t("userCenter.Codeinvalid"));
      break;
    case 40300:
      Vue.toasted.show(i18n.t("userCenter.Requestfrequently"));
      break;
    case 40011:
      Vue.toasted.show(i18n.t("userCenter.notRegister"));
      break;
    case 40012:
      Vue.toasted.show(i18n.t("userCenter.oneBind"));
      break;
    case 40306:
      Vue.toasted.show(i18n.t("userCenter.idError"));
      break;
    case 40305:
      Vue.toasted.show(i18n.t("userCenter.Forbid"));
      break;
    default:
      Vue.toasted.show("error");
  }
}
const crypto = require("crypto");

export function getSignature(initParam, time) {
  var list = Object.keys(initParam).sort();
  var rawString = "";
  for (var i = 0; i < list.length; i++) {
    rawString += initParam[list[i]];
  }
  var offset = time % 17;
  if (rawString.length > offset) {
    rawString =
      rawString.substring(offset, rawString.length) +
      rawString.substring(0, offset);
  }
  var md5 = crypto.createHash("md5");
  md5.update(rawString);
  return md5.digest("hex").toUpperCase();
}

const config = {
  timeout: 50000,
};
const service = axios.create(config);

// request拦截器
service.interceptors.request.use(
  (config) => {
    // let cookie = Cookies.get("newToken") || "";
    // if (cookie) {
    //   config.headers["YL-API-TOKEN"] = cookie;
    // }
    const token = localStorage.getItem("token");

    // if (token) {
    if (config.url && config.url.indexOf("login") == -1) {
      if (!token) {
        return;
      }
      config.headers["token"] = token;
      console.log(889999, token);
    }
    //
    // }
    if (config.url && config.url.indexOf("http") < 0) {
      config.url = "https://sdk.ulugame.com/v2" + config.url;
    }

    // if (process.env.NODE_ENV === "production") {
    //   if (/^\/api/.test(config.url)) {
    //     config.url = process.env.VUE_APP_BASEURLAPI + config.url;
    //   }
    //   if (/^\/dev-api/.test(config.url)) {
    //     config.url =
    //       process.env.VUE_APP_BASEURLNEWAPI +
    //       config.url.replace("dev-api", "prod-api");
    //   }
    // }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

//respone拦截器
service.interceptors.response.use(
  (response) => {
    console.log(90001, response.data.code);
    if (!response.data || response.data.code != 0) {
      getCode(response.data.code);
    }
    return response.data;
  },
  (error) => {
    const code = error.response.data.code;
    console.log(1233, error);
    getCode(code);

    return Promise.reject(error);
  }
);

export default service;
export const binding = (data = {}) => {
  //console.log(1233444, process.env.VUE_APP_BASEURLAPI);
  let timestamp = new Date().getTime();
  let password = "";
  if (!data.mail) {
    data.mail = "";
  }
  if (data.password) {
    var md5 = crypto.createHash("md5");
    md5.update(data.password);
    password = md5.digest("hex").toUpperCase();
  }

  const datas = {
    ...data,
    sdkVersion: "1",
    channelId:11,
    platform: 5,
    language: "zh-CN",
    timestamp,
    gameId: 100163,
    password,
    uniqueId:null
  };
  const signature = getSignature(datas, timestamp);
  const payload = {
    ...datas,
    signature,
  };
  return service({
    url: "/api/member/user/web/login",
    method: "post",
    data: payload,
  });
};
export const register = (data = {}) => {
  return axios({
    url: "/api/member/user/register",
    method: "post",
    data,
  });
};
export const appleLink = (data = {}) => {
  return axios({
    url: "/api/member/user/register",
    method: "post",
    data,
  });
};
//
export const logout = (data = {}) => {
  return service({
    url: "/api/member/user/web/logout",
    method: "post",
    data,
  });
};
//
export const getVerifyUrl = (data = {}) => {
  let timestamp = new Date().getTime();
  let datas = { ...data, timestamp };
  const signature = getSignature(datas, timestamp);
  return axios({
    url: "/api/member/nameVerify/getVerifyUrl",
    method: "post",
    data: { ...datas, signature },
  });
};
////
export const getPaymentType = (pay) => {
  // let timestamp = new Date().getTime();
  // let datas = { ...data, timestamp };
  // const signature = getSignature(datas, timestamp);
  // axios.defaults.headers["token"] =
  //   "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ7XCJnYW1lSWRcIjoxMDAxNjAsXCJ1aWRcIjpcIjE1MDk0NDM5MjQ5NzA3NTgxNDVcIixcImxhbmd1YWdlXCI6XCJlbmdsaXNoXCIsXCJ1c2VyTmFtZVwiOlwiMTUwOTQ0MzkyNDk3MDc1ODE0NVwiLFwiY2hhbm5lbElkXCI6OSxcInBsYXRmb3JtXCI6MyxcInRva2VuXCI6XCJ0b2tlblwifSIsImdhbWVJZCI6MTAwMTYwLCJ1aWQiOiIxNTA5NDQzOTI0OTcwNzU4MTQ1IiwibGFuZ3VhZ2UiOiJlbmdsaXNoIiwiY2hhbm5lbElkIjo5LCJwbGF0Zm9ybSI6MywiZXhwIjoxNjU0OTM5MTg5fQ.yWyfuSfHtdRHc9VfmzcnifIjg6iMAbgh4KYdLvxEwcxWNr7C_yOopI3OIspcdQzj3wpP50nQhWIMAIsrdYv_Vg";
  return service({
    url: `/api/fund/product/getPaymentType/100163/${pay}`,
    method: "get",
  });
};
//
export const unBind = (data) => {
  // let timestamp = new Date().getTime();
  // let datas = { ...data, timestamp };
  // const signature = getSignature(datas, timestamp);
  // axios.defaults.headers["token"] =
  //   "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ7XCJnYW1lSWRcIjoxMDAxNjAsXCJ1aWRcIjpcIjE1MDk0NDM5MjQ5NzA3NTgxNDVcIixcImxhbmd1YWdlXCI6XCJlbmdsaXNoXCIsXCJ1c2VyTmFtZVwiOlwiMTUwOTQ0MzkyNDk3MDc1ODE0NVwiLFwiY2hhbm5lbElkXCI6OSxcInBsYXRmb3JtXCI6MyxcInRva2VuXCI6XCJ0b2tlblwifSIsImdhbWVJZCI6MTAwMTYwLCJ1aWQiOiIxNTA5NDQzOTI0OTcwNzU4MTQ1IiwibGFuZ3VhZ2UiOiJlbmdsaXNoIiwiY2hhbm5lbElkIjo5LCJwbGF0Zm9ybSI6MywiZXhwIjoxNjU0OTM5MTg5fQ.yWyfuSfHtdRHc9VfmzcnifIjg6iMAbgh4KYdLvxEwcxWNr7C_yOopI3OIspcdQzj3wpP50nQhWIMAIsrdYv_Vg";
  return service({
    url: "/api/member/user/web/unBind",
    method: "post",
    data,
  });
};
//
export const sendMailCaptcha = (data) => {
  let timestamp = new Date().getTime();
  let datas = { ...data, timestamp };
  const signature = getSignature(datas, timestamp);
  return service({
    url: "/api/member/user/web/sendMailCaptcha",
    method: "post",
    data: { ...datas, signature },
  });
};

export const updatePassword = (data) => {
  let timestamp = new Date().getTime();

  let password = "";

  if (data.password) {
    var md5 = crypto.createHash("md5");
    md5.update(data.password);
    password = md5.digest("hex").toUpperCase();
  }
  let datas = { ...data, password };
  const signature = getSignature(datas, timestamp);
  // axios.defaults.headers["token"] =
  //   "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ7XCJnYW1lSWRcIjoxMDAxNjAsXCJ1aWRcIjpcIjE1MDk0NDM5MjQ5NzA3NTgxNDVcIixcImxhbmd1YWdlXCI6XCJlbmdsaXNoXCIsXCJ1c2VyTmFtZVwiOlwiMTUwOTQ0MzkyNDk3MDc1ODE0NVwiLFwiY2hhbm5lbElkXCI6OSxcInBsYXRmb3JtXCI6MyxcInRva2VuXCI6XCJ0b2tlblwifSIsImdhbWVJZCI6MTAwMTYwLCJ1aWQiOiIxNTA5NDQzOTI0OTcwNzU4MTQ1IiwibGFuZ3VhZ2UiOiJlbmdsaXNoIiwiY2hhbm5lbElkIjo5LCJwbGF0Zm9ybSI6MywiZXhwIjoxNjU0OTM5MTg5fQ.yWyfuSfHtdRHc9VfmzcnifIjg6iMAbgh4KYdLvxEwcxWNr7C_yOopI3OIspcdQzj3wpP50nQhWIMAIsrdYv_Vg";
  return service({
    url: "/api/member/user/web/updatePassword",
    method: "post",
    data: { ...datas, signature },
  });
};
//  /api/member/user/web/accountInfo
export const accountInfo = () => {
  if (!localStorage.getItem("token")) {
    return;
  }
  return service({
    url: "/api/member/user/web/accountInfo",
    method: "post",
    data: {},
  });
};

export const requestHttp = ({ url, ...datas }) => {
  // : "/api/fund/payletter/getUrl"
  let timestamp = new Date().getTime();
  let d = {
    ...datas,
    gameId: 100163,

    gameVersion: "v1",
    timestamp,
  };

  let params = { ...d, signature: getSignature(d, timestamp) };
  return service({
    url,
    method: "post",
    data: params,
  });
};
export const getPayInfoList = (language) => {
  return service({
    url: `/api/fund/product/web/100163/${language}`,
    method: "get",
  });
};
//
export const getInfo = () => { 
      return service({
        url: `/api/member/role/info/100163`,
        method: "get",
      });
}
export const infoCp = (gameId) => {
  return service({
    url: `/api/member/role/info/cp/${gameId}`,
    method: "get",
  });
};

