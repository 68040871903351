export default {
    customer: {
        'feedback': 'フィードバック',
        'scene': '問題シーン',
        'email': 'メールアドレス',
        'address': 'プレイヤーアドレス',
        'message': '伝言',
        'uploadPicture': '画像アップロード',
        'submit': '提出する',
        'received': '受け取りました',
        'checked': 'チェックしました',
        'answered': '返信済み',
        'nomore': 'ここでお問い合わせ内容を確認できます',
        'imgsize': '5M未満の画像サイズが必要',
        'usernameTip': 'ユーザー名を入力してください',
        'emailTip': '正しいメールアドレスを入力してください',
        'mesageTip': 'コメントを入力してください',
        'Feedback-list': 'お問い合わせ履歴',
        'feedback-submit': 'お問い合わせする',
        'reply': '返信',
        'underProcessing': '送信中...',
        'ok': '確認する',
        'cancel': 'キャンセル',

        'loginError': 'ログインに関する問題',
        'accountError': 'アカウントに関する質問',
        'actError': 'イベント不具合',
        'payError': 'チャージについて質問',
        'bugError': 'ゲームバグ',
        'sugError': 'ご意見・ご感想',
        'reportError': '通報',
        'otherError': 'その他',
        'gameError': 'ゲーム攻略',
        'outError': 'クラッシュ問題',

        'payAmount': '決済金額',
        'errorTime': 'エラー発生日時',
        'cus': 'コール',
        'score': '評価',
        'extOrderNo': 'オーダーナンバー',
        'extOrderNoTip': '注文番号を入力してください',

    },
    userCenter: {
        'AccountStatus': 'アカウント情報',
        'VistorUnsafe': 'ゲスト/非推奨',
        'BindingSafe': '連携済/安全',
        'Email': 'メール',
        'Forgetpassword': 'パスワードを忘れた方',
        'Bind': '連携',
        'Unbind': '解除',
        'Switch': 'アカウント切替',
        'tip': '連携することでゲームデータが紛失せず保存されます',
        'Retrievepassword': 'パスワードの再設定',
        'BindAccount': 'アカウント連携',
        'Code': '認証コード',
        'Sendcode': '送信',
        'password8': '8桁以上をパスワードを入力してください',
        'Bindsuccessfully': '連携成功',
        'Unbindsuccessfully': '解除完了',
        'validEmail': '使用できるメールアドレスを入力してください',
        'Inputcode': '認証コードを入力してください',
        'Inputpassword': 'パスワードを入力してください',
        'Modifysuccessfully': '入力情報が保存されました',
        'Databaseerror': 'データベース異常',
        'operror': 'データベース更新異常',
        'Parameterinfounsafe': 'パラメータ異常',
        'Nopermission': '権限異常',
        'Emailformatwrong': '使用できないメールアドレスです、入力情報の確認お願いします',
        'Invaildlogin': '無効なログイン、もう一度試してください',
        'Emailwasbinded': '既に連携されたアカウントです',
        'noexist': '存在しないアカウントです',
        'Codeerror': '認証番号確認失敗、再度お試しください',
        'bindedalready': '既に連携されているアカウントです',
        'ULUaccount': '既にULUアカウントと連携されています',
        'Codeinvalid': '認証コードの有効期限が切れています、再度お試しください',
        'Requestfrequently': '請求がタイムアウトしています、再度お試しください',
        'ok': '確認する',
        'cancel': 'キャンセル',
        'oneBind': 'Eメールをバインドした後、すべてのアカウントのリンクを解除することができます',
        'idError': 'アカウントが間違えました、正解のアカウントをご入力してください',
        'errorMessage1': '提出失敗',
        'errorMessage2': '参加済み'
    },
    survey: {
        survey: '調査票',
        complete: '調査は終了しました',
        tip: 'こちらが調査票です',
        tip1: '報酬を得るために記入してください',
        reward: 'アンケートの報酬',
        write: '回答する',
        next: '次へ',
        submit: '送信',
        success: 'アンケート特典が発行されました',
        success1: 'ゲームの「メールボックス」で受け取ってください',
        disabled: '入力済み',
        other: 'その他'
    }
}