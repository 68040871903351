
<template>
    <div class="success">
    
   <div v-show="showModalend" class="box8s">
       
    </div>
    <div v-show="showModalend" class="showModalend">
       <p v-html="this.userShowpay"></p>
       <div></div>
        <p @click="btnend">確認</p>
    </div>
  
    </div>
</template>
<script>

//1失败，2取消
export default {
    data() {
        return {
            showModalend:true,
            userShowpay:''
        }
    },
    methods: {
        btnend(){
          this.showModalend=false
          window.location.href='https://pay.ulugame.com/#/'
        },
    },
  mounted() { 
     
    },
    created(){
        this.userShowpay=this.$t('userCenter.paySuccess')
    },
    computed: {

       
    },
        watch: {
           
        }
    

}
</script>
<style lang="scss" scoped>
.success{
    width: 100vw;
    height: 100vh;
    background-image: url("../assets/pay/success.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.box8s{
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/GAT/greyBG.png");
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}

</style>