export default {
  customer: {
    feedback: "피드백",
    scene: "오류설명",
    email: "메일",
    address: "주소",
    message: "댓글",
    uploadPicture: "사진첨부",
    submit: "제출",
    received: "수락됨",
    checked: "확인함",
    answered: "답변완료",
    nomore: "가(이)없습니다~",
    imgsize: "용량이5M를초과할수없습니다",
    usernameTip: "ID를입력하세요",
    emailTip: "사용가능한메일주소를입력하세요",
    mesageTip: "댓글을입력하세요",
    "Feedback-list": "피드백",
    "feedback-submit": "제출",
    reply: "답변",
    underProcessing: "제출중...",
    ok: "확인하다",
    cancel: "취소",

    loginError: "로그인 문의",
    accountError: "계정문의",
    actError: "이벤트 문의",
    payError: "결제문의",
    bugError: "버그신고",
    sugError: "건의사항",
    reportError: "신고",
    otherError: "기타",
    gameError: "게임공략",
    outError: "렉 문의",

    payAmount: "결제 금액",
    errorTime: "오류 발생 시간",
    cus: "고객선터",
    score: "등급",
    extOrderNo: "주문 번호",
    extOrderNoTip: "주문 번호를 입력하십시오",
  },
  userCenter: {
    AccountStatus: "계정 상태",
    VistorUnsafe: "게스트 계정/안전하지 않음",
    BindingSafe: "연동됨/안전",
    Email: "이메일",
    Forgetpassword: "비밀번호 찾기",
    Bind: "연동",
    Unbind: "연동 해제",
    Switch: "계정 전환",
    tip: "계정 연동 기능을 사용하시면 더욱 안전하게 계정 보호가 가능합니다. ",
    Retrievepassword: "비밀번호 재설정",
    BindAccount: "계정 연동",
    Code: "인증번호",
    Sendcode: "인증번호 발송",
    password8: "8자리 이상",
    Bindsuccessfully: "연동 성공",
    Unbindsuccessfully: "연동 해제 성공",
    validEmail: "정확한 이메일 주소를 입력하세요",
    Inputcode: "인증번호를 입력하세요",
    Inputpassword: "비밀번호를 입력하세요",
    Modifysuccessfully: "변경 성공",
    Databaseerror: "데이터 오류",
    operror: "데이터 조작 오류",
    Parameterinfounsafe: "입력 정보가 완전하지 않습니다",
    Nopermission: "권한이 없습니다",
    Emailformatwrong: "이메일 양식이 맞지 않습니다",
    Invaildlogin: "무효 로그인， 다시 시도 하세요",
    Emailwasbinded: "이 이메일은 이미 연동되어 있습니다",
    noexist: "계정이 존재하지 않습니다",
    Codeerror: "인증번호 틀림",
    bindedalready: "다른 계정으로 연동되어 있습니다",
    ULUaccount: "이 계정은 ULU계정과 연동되어 있습니다",
    Codeinvalid: "유효시간 지남",
    Requestfrequently: "잠시 후 다시 시도하세요 ",
    ok: "확인하다",
    cancel: "취소",
    oneBind: "다른 모든 계정을 바인딩 해제하기 전에 이메일을 제본하십시오.",
    idError: "계정 오류, 연동 해제가 필요한 정확한 계정을 입력해주세요.",
    errorMessage1: "제출실패",
    errorMessage2: "중복참가",
    privatePolitics: "운영 약관",
    privateInfo: "프라이버시 약관",
    notRegister: "미 등록 계정입니다.",
    confirmPrivate: "운영 약관을 확인해 주세요",
    emailLogin: "메일 접속",
    email: "메일",
    password: "비밀번호",
    rewirte: "수정",
    rewritepassword: "비밀번호 수정",
    user: "계정",
    logout: "로그아웃",
    clickLogin: "로그인",
    codeSend: "이미 인증 코드를 발송하였습니다.",
    notLogin: "로그인해 주세요",
    passwordError: "비밀번호 오류",
    selectItem: "상품을 선택해 주세요",
    itemName: "상품명",
    serverName: "서버",
    roleId: "캐릭터 ID",
    roleName: "닉네임",
    passCar: "계정",
    payWay: "결제 방식",
    bankCard: "신용 카드 / 체크 카드",
    eWallet: "기타 결제",
    choosePay: "인증 방식을 선택해 주세요",
    creditCard: "신용 카드",
    phone: "스마폰",
    confirmSuccess: "인증에 성공하셨습니다.",
    cancelInfo: "인증을 취소 하셨습니다.",
    InfoFail: "인증에 실패 하셨습니다.",
    paySuccess: "결제 성공",
    payFail: "결제 실패",
    children: "미성년은 게임 이용할 수 없습니다.",
  },
  survey: {
    survey: "서베이",
    complete: "설문 조사가 끝났습니다",
    tip: "설문 조사 설문지입니다",
    tip1: "보상을 받으려면 입력하세요",
    reward: "설문조사에 대한 리워드",
    write: "설문 응답하기",
    next: "다음",
    submit: "질문 제출",
    success: "설문지 보상이 발행되었습니다",
    success1: '게임의 "우편함"에서 받으십시오',
    disabled: "작성 완료",
    other: "기타",
  },
};
