<!--
 * @Author: liuyufeng tom.liu@ulugame.com
 * @Date: 2022-06-15 18:38:32
 * @LastEditors: liuyufeng tom.liu@ulugame.com
 * @LastEditTime: 2022-07-15 15:23:06
 * @FilePath: \windowssdk\src\components\payDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 roleId:"角色id",
roleName:"角色昵称",
passCar:"通行证",
payWay:"支付方式",
bankCard:"信用卡/借记卡",
eWallet:"电子钱包",
choosePay:"请选择实名认证方式",
creditCard:"信用卡",
phone:"手机",
confirmSuccess:"认证完成，登录成功",
cancelInfo:"已取消实名认证",
InfoFail:"实名认证失败",
paySuccess:"支付完成",
payFail:"支付失败",
children:"未成年",
-->
<template>
    <div v-if="showPay" style="background-color:rgba(0,0,0,0.5);position:fixed;z-index:1200;width:100vw;height:100vh">
        <div  class="pay-dialog">
            <img @click="setShowPay(false)" style="position:absolute;right:0;top:-50px" :src="require('../assets/pay/close.png')">
            <div class="Tips_flex">

            
            <div class="select_params">
                <div>
                     <span>伺服器</span>
                     <!-- <el-dropdown  @command="changeServerItem" placement="bottom-start"> -->
                       
                             <div v-if="serverInfo.serverName" class="change_server">{{serverInfo.serverName}}</div>
                             <!-- <el-dropdown-menu slot="dropdown">
                                 <el-dropdown-item :command="item" v-for="(item,index) in roleList" :key="index">{{item.serverName}}</el-dropdown-item>
                             </el-dropdown-menu> -->
                         
                     <!-- </el-dropdown> -->
                    
                </div>
               <div>
                   <span>角色名</span>
                   <el-dropdown @command="changeRoleItem" placement="bottom-start">
                        
                         <div><div class="change_server" v-if="serverInfo.roleName&&serverInfo.roleId">{{serverInfo.roleName}}</div><img v-else :src="require('../assets/pay/choose_role.png')"></div>
                         <el-dropdown-menu slot="dropdown">
                             <el-dropdown-item :command="item" v-for="(item,index) in roleList" :key="index">{{item.roleName}}</el-dropdown-item>
                             </el-dropdown-menu>
                   </el-dropdown>
               </div>
            
            </div>
            <div class="Tips">
                <p>
                    注意：透過此儲值方式進行儲值，無法享受「首儲雙倍金幣」
                </p>
                <p>
                    僅可享受MyCard平臺的儲值返利。
                </p>
                <p>請確認您充分了解上述規則后進行儲值操作，感謝您的理解和支持。</p>
                
                
            </div>
            </div>
            <!-- <img :src="require('../assets/close.png')" class="close-img"> -->
            <!-- <div class="first-title">
                {{$t('userCenter.selectItem')}}
            </div> -->
            <div class="divider-1"><span>儲值商品內容</span><div></div></div>
            <div class="sale_item">
                <div v-for="(item,index) in payInfoList" :key="index" @click="selectItem(item,index)" :style="{ 'background-image': `url(${item.check?require('../assets/pay/selected.png'):require('../assets/pay/unselect.png')})`}">
                  <div>
                      <div>
                            <img :src="require('../assets/pay/coin.png')">
                            <span>{{item.productName}}</span>
                      </div>
                                          <span>{{item.mark}}{{item.price}}</span>
                  </div>
                </div>
            </div>
            <div class="divider-1" style="margin-top:1.5vw"><span>請選擇支付方法</span><div></div></div>
            <img style="width:9.5vw;height:4.2vw; cursor: pointer;" :src="require('../assets/pay/mycard_button.png')">
            <img  @click="payOrder('mycard')" style="width:13.8vw;height:3.8vw; cursor: pointer;position:absolute;bottom:3.3vw;left:50%;transform:translateX(-50%)" :src="require('../assets/pay/pay_bt.png')">
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions,mapMutations } from "vuex"
import { requestHttp,getPayInfoList,getInfo,infoCp } from "../util/request";
//1失败，2取消
export default {
    data() {
        return {
            isChoose: '',
            payInfoList: [],
            payCurrency: '',
            requestData: {},
            roleList: [],
            showToast: false,
            serverInfo: {},
        }
    },
    methods: {
        ...mapMutations(['setToken','setIsLoading','setShowPay']),
        ...mapActions(['setPayInfo']),
        changeServerItem(
            e
        ) {
            console.log(111189, e.serverName)
            // if (e) { 
            //     this.serverInfo[]
            // }
        },
        changeRoleItem(e) { 
            console.log(111189, e)
            if (e) { 
                this.serverInfo=e
            }
        },
        selectItem(item = {}, index) {
            if (this.payInfoList&&this.payInfoList.length < 1) { 
                return
            }
            this.payInfoList = this.payInfoList.map(item => { 
                item.check =false
                return item
            })
            this.requestData= { payCurrency:item.currency,uluProductId:item.uluProductCode,payAmount:item.price}
            this.$set(this.payInfoList, index, {...item,check:true})
         },
        async payOrder(pgcode) {
           
            this.isChoose = pgcode
            let isEmpty = false
            
            if (this.serverInfo) {
                if (Object.values(this.serverInfo).length < 4) { 
                     isEmpty= true
                }
            } else{
 isEmpty= true
            }
             console.log(12999,isEmpty,Object.values(this.serverInfo))
            if (isEmpty) {
                this.$toasted.show('請選擇游戲角色')
                return
            }
            if (this.payInfoList && this.payInfoList.length > 0 && (!this.requestData.payCurrency && !this.requestData.uluProductId && !this.requestData.payAmount)) {
                let item = this.payInfoList[0]
                   //console.log(71883, item)
                  this.requestData= { payCurrency:item.currency,uluProductId:item.uluProductCode,payAmount:item.price}
            }
             //console.log(71882, this.requestData.payCurrency)
            // if (!this.requestData.payCurrency) { 
            //     return
            // }
            if (localStorage.getItem('token')) {
                //if () { }
                // if (!this.serverInfo.signature || !this.serverInfo.gameId) {
                    
                //  }
                //  console.log(999,localStorage.getItem('token'))
               // console.log(1111111111111111,this.payCurrency)
               // this.setIsLoading(true)
                await this.setPayInfo(this.requestData.payCurrency)
                console.log(89911, this.serverInfo)
                let params = {
                    sandBoxMode: 'true',
                    ...this.requestData,
                    serverName: this.serverInfo.serverName,
                    serverId: this.serverInfo.serverId,
                    roleId: this.serverInfo.roleId,
                     roleName: this.serverInfo.roleName,
                    extraData: "",
                    quantity:1,
                    uid: this.uid,
                }
                //console.log(1111111112,this.serverInfo)
                  // 
                requestHttp({ url: this.payTypeList[0].paymentChannelRequestUrl, ...params }).then((res = {}) => {
                    //console.log(9000, res)
                    this.setShowPay(false)
                    this.setIsLoading(false) 
                    const { code, data } = res
                    if (code == 0 && data) {
                        window.location.href = data
                    }
                });
            } else {
                this.$toasted.show(this.$t('userCenter.notLogin'))
                window.location="/"
            }

        }
    },
  mounted() { 
     
    },
    computed: {

        ...mapGetters(['token', 'payTypeList', 'uid', 'lan', 'lanuage','showPay']),
        showPayButton() {
            return !!this.requestData.payCurrency
         }
    },
        watch: {
           async showPay(val){
                if(val==true){
                     if (localStorage.getItem('token')) {
              this.setToken(localStorage.getItem('token'))
            infoCp('100163').then(res => { 
                const { data } = res
                this.roleList=data
                //console.log(8811122,res)
            })
          
            const res = await getInfo()
                const { data:data1 } = res
                this.showToast = !!data1
            if (data1) {
                let isEmpty = false
                for (let key in data1) { 
                    if (!data1[key]) {
                        isEmpty= true
                     }
                }
                    !isEmpty&&(this.serverInfo=data1)
            }
           // if () { }
                const { data} = await getPayInfoList(this.lan[this.lanuage])
                //console.log(1255322, data)
                if (data) {
                    this.payInfoList = data.map((item,i) => { 
                        if (i == 0) {
                            item.check = true
                         }
                        return item
                    })
                }
    }
                }
            }
        }
    

}
</script>
<style lang="scss" scoped>
.pay-dialog {
    background-image: url('../assets/pay/pya_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 50vw;
    height: 38vw;
    position: absolute;
    cursor: pointer;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    padding-left: 3.8rem;

    &>.close-img {
        height: 1.25rem;
        width: 1.25rem;
        align-self: flex-end;
        margin: 1.2rem;

    }

    &>.first-title {
        font-size: 1.5rem;
        align-self: flex-start;
    }

    &>.item-f {
        font-size: 1rem;
        align-self: flex-start;
        margin-top: 2rem;
        margin-bottom: 2rem;

    }

    &>.item-info {
        // background-color: #D8D8D8;
        // width: calc(100% - 7.8rem);
        display: flex;
        flex-direction: row;
        color: #595959;
        font-size: 1.3rem;
        padding: 2rem;

        &>.items {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &>:nth-child(even) {
                margin-bottom: 1rem;
                margin-top: 1rem;
            }
        }

        &>.items2 {
            align-items: flex-start;
        }
    }

}

.way-to-pay {
    width: 12.5vw;
    height: 3.2vw;
    position: relative;
    cursor: pointer;
    &>:nth-child(1) {
        width: 99.5%;
        height: 99%;
        position: absolute;
        left: 0;
         z-index:10;
    }

    &>:nth-child(2) {
        width: 100%;
        height: 100%;
        position: absolute;
       
        left: 0;
    }
}

.pay-list {
    display: flex;
    flex-direction: row;

    &>div {
        width: 12.5vw;
        margin-right: 1.2vw;
        cursor: pointer;
        position: relative;
        &>:nth-child(1){
            position: absolute;
            z-index: 10;
            left: 0;
        }
        &>img{
            width: 100%;
        }
    }
}
.Tips_flex{
    display: flex;
    .select_params{
    margin-top:3.3vw;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    & .change_server{
        font-size: 19px;
        color: #a1a1a1;
        max-width: 10vw;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &>div{
        width: 8vw;
        display: flex;
        flex-direction: column;
        &>img{
            height: 1rem;
            width: 5rem;
        }
    }
    & select{
        width:4vw;
    }

    & span{
        font-size: 1rem;
font-family: Source Han Serif SC;
font-weight: 800;
color: #C2A75A;
    }
}
.Tips{
    position: absolute;
    left: 40%;
    top: 2vw;
    font-size: 0.9vw;
    color: #dcc74c;
    font-weight: bold;
}
}

.divider-1{
    margin-bottom: 1vw;
    margin-top:2vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 5rem);
    &>span{
        font-size: 1rem;
font-family: Source Han Serif SC;
font-weight: 800;
color: #C2A75A;

    }
    
    &>div{
        margin-left: 1.5vw;
          box-shadow: 0 1px 0 #C2A75A;
border-bottom: solid 1px #C2A75A;
transform: scaleY(0.5);
transform-origin: 50% 100%;
flex:1
    }

}
.sale_item{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    cursor: pointer;
    height:16vw;
    width: 46vw;
    overflow-y: scroll;
    &>div{
        margin-right: 1.8vw;
        width: 11.8vw;
        height:7.8vw;
        background-size:100% 100%;
        background-repeat: no-repeat;
        &>div{
            margin-top:1.6vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            &>:nth-child(1){
                 display: flex;
                 flex-direction: row;
                 align-items: center;
                  &>img{
                      margin-right: 0.5vw;
                width:2.5vw;
                height:2.5vw;
            }
           &>span{
        font-size: 1.2vw;
// font-family: Source Han Serif SC;
font-weight: 600;
color: #FFFFFF;
overflow: hidden;
margin-right: 1vw;
// word-wrap: break-word;
// white-space: pre-wrap;
// vertical-align: middle;
max-width: 6vw;
//  scrollbar-width: none; /* firefox */
//    -ms-overflow-style: none; /* IE 10+ */
           }
           
            }
             &>:nth-child(2){
                 font-size: 1.2rem;
                 margin-top:8px;
             }
          
        }
    }
}
.sale_item::-webkit-scrollbar {

width: 18px;

}
.sale_item::-webkit-scrollbar-thumb{
border-radius: 5px;
background-image:url("../assets/pay/roll2.png");
background-size: 100% 100%;
}
.sale_item::-webkit-scrollbar-track {
background-image:url("../assets/pay/roll1.png");
background-size: 100% 100%;

}
</style>